import {
  Box,
  Modal,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Headline2 from "../../../subComponents/pageUI/Headline2";
import CustomTextField from "../../../subComponents/formUI/CustomTextField";
import classes from "./Campus.module.css";
import CustomButton from "../../../subComponents/formUI/CustomButton";
import CustomSelect from "../../../subComponents/formUI/CustomSelect";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { updateSequence } from "../../../services/campus";
import { defaultFormat } from "moment/moment";

const CodeListDropdown = ({ codeList, onCodeSelect }) => {
  return (
    <Paper
      sx={{
        zIndex: 1,
        width: "100%",
        maxHeight: "200px",
        overflowY: "auto",
        backgroundColor: "background.paper",
        borderRadius: "0.5rem",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <MenuList>
        {codeList?.length > 0 && (
          codeList?.map((code) => (
            <MenuItem
              key={code}
              onClick={() => onCodeSelect(code?.organizationCode)}
              sx={{
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              {code?.organizationCode}
            </MenuItem>
          ))
        )}
      </MenuList>
    </Paper>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "80%",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  width: 650,

  border: "none",
  borderRadius: "1rem",
  bgcolor: "background.paper",

  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const closeIconStyle = {
  float: "right",
  fill: "#a19d9d",
  cursor: "pointer",
};

const CampusModal = ({
  cityList = cityList,
  open: _open,
  onSubmit,
  children,
  defaultData,
}) => {
  const [open, setOpen] = useState(_open || false);
  const [initialState] = useState({
    campusName: defaultData?.campusName || "",
    cityId: defaultData?.cityId.cityId || "",
    address: defaultData?.address || "",
    tag: defaultData?.tag || "",
    isActive: defaultData?.isActive || false,
    latitude: defaultData?.latitude || "",
    longitude: defaultData?.longitude || "",
    organizationCode: defaultData?.Organization?.organizationCode || "",
    organizationId:defaultData?.organizationId || "",
  });
  console.log("Default data ", defaultData?.organizationList);
  const [formData, setFormData] = useState(initialState);
  const [outletData, setOutletData] = useState([]);
  const [showCodeDropdown, setShowCodeDropdown] = useState(false);

  const [codeList,setCodeList] = useState(defaultData?.organizationList);
  const [filteredCodes, setFilteredCodes] = useState(defaultData?.organizationList);

  const toggleCodeDropdown = () => {
    setShowCodeDropdown((prev) => !prev);
  };

  const handleCodeSelect = (code) => {
    setFormData({ ...formData, organizationCode: code });
    setShowCodeDropdown(false);
  };

  const handleCodeInputChange = (e) => {
    const inputValue = e.target.value;

    const filtered = codeList?.filter((code) =>
      code?.organizationCode?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setFilteredCodes(filtered);
    setShowCodeDropdown(true);
  };

  // console.log("campusId ", formData);
  const [selectedValue, setSelectedValue] = useState(defaultData?.isActive);
  // const optionList = [{ name: "Active" }, { name: "Inactive" }];
  useEffect(() => {
    defaultData &&
      setFormData({
        campusId: defaultData?.campusId || "",
        campusName: defaultData?.campusName || "",
        cityId: defaultData?.cityId.cityId || "",
        address: defaultData?.address || "",
        tag: defaultData?.tag || "",
        isActive: defaultData?.isActive || false,
        latitude: defaultData?.latitude || "",
        longitude: defaultData?.longitude || "",
        organizationCode: defaultData?.Organization?.organizationCode || "",
        organizationId:defaultData?.organizationId || "",
      });

    defaultData && getOutletData();
  }, [defaultData]);

  const getOutletData = async () => {
    const outletData = await axios.get(
      `https://api.mealpe.app:8055/outlet/getOutletsbyCampusId/${defaultData?.campusId}`
    );
    if (outletData?.data?.success === true) {
      setOutletData(outletData?.data?.data);
    }
  };

  // console.log("data 82 ", outletData);

  const [isCampusNameTouched, setCammpusNameTouched] = useState(false);
  const [isCampusAddressTouch, setCampusAddressTouched] = useState(false);
  const [isCampusTagTouch, setCampusTagTouched] = useState(false);
  // const [isCampusCityTouch, setIsCampusCityTouched] = useState(false)

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name == "organizationCode") {
      handleCodeInputChange(e);
    }
  };
  const {
    campusName,
    cityId,
    address,
    tag,
    latitude,
    longitude,
    organizationCode,

  } = formData;

  const isFormValid = () => {
    return (
      campusName?.length >= 3 &&
      /^[a-zA-Z0-9!@#$%^&*)(](\s?[a-zA-Z0-9!@#$%^&*)(]){2,29}$/.test(
        campusName
      ) &&
      campusName?.length <= 30 &&
      cityId &&
      address?.length >= 7 &&
      address?.length <= 30
    );
  };

  const handleClose = () => {
    setFormData("");
    setOpen(!open);
    // setFormData(defaultData)
  };
  const handleSubmit = () => {
    console.log("formdata ", formData);
    onSubmit?.(formData);
    handleClose();
    setFormData(initialState);
  };

  const handleEdit = () => {
    console.log("formdata ", formData);
    updateOutletSequence(outletData);
    onSubmit?.(formData);
    handleClose();
  };

  const updateOutletSequence = async (outletData) => {
    updateSequence(outletData)
      .then((res) => {
        if (res?.data?.success) {
          console.log("sequence added");
        }
      })
      .catch((error) => {
        console.log("Error while update sequence ", error);
      });
  };

  const handleCategoryChange = (e) => {
    setSelectedValue(e.target.checked);

    // if (e.target.checked === false) {
      setFormData({ ...formData, isActive: e.target.checked });
    // } else setFormData({ ...formData, isActive: false });
  };

  // function transformId(id) {
  //   const parts = id.split("-");
  //   return parts[parts.length - 1].slice(0, 2); // Get the last part and then take the first two characters
  // }

  const handleSequenceChange = (e, index) => {
    const newSequence = e.target.value;
    const updatedOutletData = [...outletData];
    updatedOutletData[index].sequence = newSequence === "" ? null : newSequence;
    setOutletData(updatedOutletData);

    console.log("handle change ", outletData);
  };

  return (
    <>
      <div onClick={() => setOpen(!open)}>{children}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 630 }}>
          <div>
            <CloseIcon style={closeIconStyle} onClick={handleClose} />
            <Headline2
              children={defaultData ? "Update Campus " : "Add Campus "}
              sx={{
                fontSize: "1.25rem",
                fontWeight: "100",

                color: "black",
              }}
            />

            <Headline2
              children={
                defaultData
                  ? "Update Campus For Restaurant Management"
                  : "Add Campus For Restaurant Management"
              }
              sx={{
                fontSize: "0.9rem",
                fontWeight: "0",

                color: "#94A3B8",
              }}
            />
          </div>

          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <div className={classes.formTextFieldsModal}>
              <CustomTextField
                sx={{ marginBottom: "1rem" }}
                hasError={
                  !(
                    campusName?.trim() !== "" &&
                    /^[a-zA-Z0-9!@#$%^&*)(](\s?[a-zA-Z0-9!@#$%^&*)(]){2,29}$/.test(
                      campusName
                    )
                  ) &&
                  isCampusNameTouched &&
                  campusName?.length > 0
                }
                errorMassage={"Please enter a valid Campus Name"}
                label="Enter Campus Name"
                required
                name={"campusName"}
                placeholder="Enter campus name"
                value={campusName}
                onChange={onChange}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value.slice(0, 30);
                  }
                }}
                onBlur={(e) => setCammpusNameTouched(true)}
              />

              <CustomSelect
                sx={{ marginBottom: "1rem" }}
                key={cityId}
                required
                name={"cityId"}
                value={cityId}
                optionList={cityList}
                label="Select City"
                placeholder="Select"
                // onBlur={(e) => setIsCampusCityTouched(true)}
                onChange={onChange}
              />
              <CustomTextField
                sx={{ marginBottom: "1rem" }}
                required
                name={"address"}
                value={address}
                hasError={
                  !(
                    address?.trim() !== "" &&
                    address?.length >= 7 &&
                    address?.length <= 30
                  ) &&
                  isCampusAddressTouch &&
                  address?.length > 0
                }
                errorMassage={"This value must be between 7 to 30 characters"}
                label="Address"
                placeholder="Enter campus address"
                onBlur={(e) => setCampusAddressTouched(true)}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value
                      .replace(/[^a-zA-Z0-9\s]/gi, "")
                      .slice(0, 30);
                  }
                }}
                onChange={onChange}
              />
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <CustomTextField
                  sx={{ marginBottom: "1rem" }}
                  name={"latitude"}
                  value={latitude}
                  label="Latitude"
                  placeholder="Latitude"
                  onBlur={(e) => setCampusTagTouched(true)}
                  onInput={(e) => {
                    if (e?.target?.value) {
                      e.target.value = e?.target?.value;
                    }
                  }}
                  onChange={onChange}
                />{" "}
                <CustomTextField
                  sx={{ marginBottom: "1rem" }}
                  name={"longitude"}
                  value={longitude}
                  label="Longitude"
                  placeholder="Longitude"
                  onBlur={(e) => setCampusTagTouched(true)}
                  onInput={(e) => {
                    if (e?.target?.value) {
                      e.target.value = e?.target?.value;
                    }
                  }}
                  onChange={onChange}
                />
              </Box>

              <CustomTextField
                sx={{ marginBottom: "1rem" }}
                name={"tag"}
                value={tag}
                label="Tag"
                placeholder="Enter campus tag"
                onBlur={(e) => setCampusTagTouched(true)}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value;
                  }
                }}
                onChange={onChange}
              />

              {/* <CustomTextField
                sx={{ marginBottom: "1rem" }}
                name={"organizationCode"}
                value={organizationCode}
                label="Organization Code"
                placeholder="Enter Code"
                onBlur={(e) => setCampusTagTouched(true)}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value;
                  }
                }}
                onChange={onChange}
              /> */}
              <CustomTextField
                name="organizationCode"
                value={organizationCode}
                label="Organization Code"
                onClick={toggleCodeDropdown}
                placeholder="Enter Code"
                onBlur={(e) => {
                  setCampusTagTouched(true);
                  setShowCodeDropdown(false);
                }}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value;
                    // handleCodeInputChange(e);
                  }
                }}
                onChange={onChange}
              />
              {showCodeDropdown && (
                <CodeListDropdown
                  codeList={filteredCodes}
                  onCodeSelect={handleCodeSelect}
                />
              )}

              {defaultData && outletData?.length !== 0
                ? outletData?.map((outlet, index) => (
                    <div
                      key={outlet?.outletId}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <CustomTextField
                        sx={{ marginBottom: "1rem" }}
                        name={"outlet"}
                        value={outlet?.outletName}
                        label={`Outlet ${index + 1}`}
                        style={{
                          width: "33rem",
                        }}
                      />
                      <CustomTextField
                        sx={{ marginBottom: "1rem" }}
                        name={"sequence"}
                        value={
                          outlet?.sequence === null ? "" : outlet?.sequence
                        }
                        label="sequence"
                        placeholder="Set No."
                        onChange={(e) => handleSequenceChange(e, index)}
                        style={{
                          width: "5rem",
                        }}
                      />
                    </div>
                  ))
                : "This campus not have any outlets."}

              {defaultData && (
                <>
                  <FormControlLabel
                    sx={{ justifyContent: "flex-end" }}
                    control={
                      <Switch
                        // defaultChecked
                        name={"isActive"}
                        checked={selectedValue}
                        onChange={(e) => handleCategoryChange(e)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                  {/* <Typography marginBottom={'-13px'}>Status</Typography>
                              <CustomRadio
                                value={selectedValue}
                                optionList={optionList}
                                handleCategoryChange={handleCategoryChange}
                                // gap={10}
                                // radioContainerStyle={{display:"flex",width:"174.75px",padding:"12px 16px",alignItems:"flex-start",gap:"10px"}}
                                sx={{
                                  display: "flex",
                                  width: "174.75px",
                                  padding: "12px 16px",
                                  alignItems: "flex-start",
                                  alignItems: "center",
                                  gap: "10px",
                                  flexShrink: "0",
                                  borderRadius: "12px",
                                  border: "1px solid var(--gray-200, #E2E8F0)",
                                  // marginLeft:"5px",
                                }}
                                radioContainerStyle={{
                                  gap: "5px",
                                  marginTop: "5px",
                                  marginLeft: "15px",
                                }}
                              /> */}
                </>
              )}
            </div>
          </div>
          <div className={classes.cancelSaveBtn}>
            <CustomButton
              type="submit"
              onClick={() => handleClose()}
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", margin: "0.6rem" },
                width: "180px",
                backgroundColor: "white",
                border: "1px solid #CBD5E1",
              }}
              textcontent="Cancel"
            />
            <CustomButton
              type="submit"
              onClick={defaultData ? handleEdit : () => handleSubmit()}
              disabled={!isFormValid()}
              variant="contained"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", margin: "0.6rem" },
                width: "180px",
              }}
              textcontent={defaultData ? "Update" : "Save"}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CampusModal;

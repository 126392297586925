import React, { useEffect, useState } from "react";
//* import css
import cssClasses from "../Dashboard/Dashboard.module.css";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DataGrid } from "@mui/x-data-grid";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Typography, Link, TextField, Checkbox, Box } from "@mui/material";
import {
  getFinanceDetails,
  getFinanceDashBoard,
  getAllRestaurant,
  getFinanceOrderReport,
} from "../../../services/finance";
import { dismissLoader, presentLoader } from "../../../services/loaderService";
import ExcelJS from "exceljs";
import { errorToast } from "../../../services/toast";
import PaperBox from "../../../subComponents/pageUI/PaperBox";
import { FinanceCards } from "./FinanceCards";
import CustomCheckbox from "../../../subComponents/formikFormUI/CustomCheckbox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const Finance = () => {
  const [financeDetails, setFinanceDetails] = useState([]);
  const [dateFrom, setDateFrom] = useState(dayjs(new Date()));
  // eslint-disable-next-line
  const [dateTo, setDateTo] = useState(dayjs(new Date()));
  // eslint-disable-next-line
  const [minDate, setMinDate] = useState(dayjs(new Date()));
  const [isOpen, setIsOpen] = useState(false);
  const [Open, setOpen] = useState(false);
  const [cardsData, setCardsData] = useState({});
  const [outletIds, setOutletIds] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [online, setOnline] = useState(true);
  const [offline, setOffline] = useState(false);

  const cardData = online && offline
  ? [
      // Case: Both online and offline
      {
        title: "Total GMV",
        description:
          "Gross Merchandise Value (GMV) is a metric that measures your total value of sales over a certain period of time. Sales Price of Goods x Number of Goods Sold.",
        value: getFormattedCurrency(cardsData?.gmv || 0) || 0,
      },
      {
        title: "EComm. GST",
        description: "It is the Total of (Food GST + 5%(Packaging + Delivery)).",
        value: getFormattedCurrency(cardsData?.ecomm_gst || 0) || 0,
      },
      {
        title: "MealPe Fees",
        description: "It is the Total of (Commission + Convenience Fee)",
        value: getFormattedCurrency(cardsData?.mealpe_fees || 0),
      },
      {
        title: "Tax on MealPe Fees",
        description: "It is the Total of 18% of MealPe Fees",
        value: getFormattedCurrency(cardsData?.tax_on_mealpe_fees || 0),
      },
      {
        title: "TDS",
        description: "1% of Total GMV",
        value: getFormattedCurrency(cardsData?.tds || 0),
      },
      {
        title: "Cash to be Collected",
        description: "It is the Total of (MealPe Fees + Taxes on MealPe Fees)",
        value: getFormattedCurrency(cardsData?.cash_to_be_collected || 0),
      },
    ]
  : online
  ? [
      // Case: Online only
      {
        title: "Total GMV",
        description:
          "Gross Merchandise Value (GMV) is a metric that measures your total value of sales over a certain period of time. Sales Price of Goods x Number of Goods Sold.",
        value: getFormattedCurrency(cardsData?.gmv || 0) || 0,
      },
      {
        title: "EComm. GST",
        description: "It is the Total of (Food GST + 5%(Packaging + Delivery)).",
        value: getFormattedCurrency(cardsData?.ecomm_gst || 0) || 0,
      },
      {
        title: "MealPe Fees",
        description: "It is the Total of (Commission + Convenience Fee)",
        value: getFormattedCurrency(cardsData?.mealpe_fees || 0),
      },
      {
        title: "Tax on MealPe Fees",
        description: "18% of MealPe Fees",
        value: getFormattedCurrency(cardsData?.tax_on_mealpe_fees || 0),
      },
      {
        title: "TDS",
        description: "1% of Total GMV",
        value: getFormattedCurrency(cardsData?.tds || 0),
      },
    ]
  : offline
  ? [
      // Case: Offline only
      {
        title: "Total GMV",
        description:
          "Gross Merchandise Value (GMV) is a metric that measures your total value of sales over a certain period of time. Sales Price of Goods x Number of Goods Sold.",
        value: getFormattedCurrency(cardsData?.gmv || 0) || 0,
      },
      {
        title: "MealPe Fees",
        description: "It is the Total of (Commission + Convenience Fee)",
        value: getFormattedCurrency(cardsData?.mealpe_fees || 0),
      },
      {
        title: "Tax on MealPe Fees",
        description: "It is the Total of 18% of MealPe Fees",
        value: getFormattedCurrency(cardsData?.tax_on_mealpe_fees || 0),
      },
      {
        title: "Cash to be Collected",
        description: "It is the Total of (MealPe Fees + Taxes on MealPe Fees)",
        value: getFormattedCurrency(cardsData?.cash_to_be_collected || 0),
      },
    ]
  : [
      // Case: Neither online nor offline
      {
        title: "Total GMV",
        description:
          "Gross Merchandise Value (GMV) is a metric that measures your total value of sales over a certain period of time. Sales Price of Goods x Number of Goods Sold.",
        value: getFormattedCurrency(cardsData?.gmv || 0) || 0,
      },
      {
        title: "MealPe Revenue",
        description:
          "It is the Total of (Commission + Convenience Fee + GST on Commission and Convenience Fees).",
        value: getFormattedCurrency(cardsData?.total_mealpe_revenue || 0) || 0,
      },
      {
        title: "Total Orders Processed",
        description:
          "It is the number of orders processed in Time range and selected outlet.",
        value: intToString(cardsData?.total_orders || 0),
      },
      {
        title: "Total Customers",
        description:
          "It is the number of active interactions from mobile app users.",
        value: intToString(cardsData?.total_customer || 0),
      },
      {
        title: "Total Restaurants",
        description: "It is the number of restaurants.",
        value: intToString(cardsData?.total_outlets || 0),
      },
    ];


  const handleDownload = (id) => {
    const params = {
      start_date: dayjs(dateFrom).format("YYYY/MM/DD"),
      end_date: dayjs(dateTo).format("YYYY/MM/DD"),
      outletId: id,
    };
    presentLoader();
    getFinanceOrderReport(params)
      .then((res) => {
        generateExcel(res.data.data);

        dismissLoader();
      })
      .catch((err) => {
        console.log(err);
        let _err = err?.response?.data;
        let errMsg = "Something went wrong please try again.";
        if (typeof _err?.message === "string") {
          errMsg = _err?.message;
        } else if (typeof _err === "string") {
          errMsg = _err;
        }
        errorToast(errMsg);
        dismissLoader();
      });
  };

  useEffect(() => {
    presentLoader();

    if (outlet?.length < 1) {
      getAllRestaurantApiCall();
    }

    const params = {
      start_date: dayjs(dateFrom).format("YYYY/MM/DD"),
      end_date: dayjs(dateTo).format("YYYY/MM/DD"),
      outlets: outletIds,
      isOnline: online,
      isCash: offline,
    };
    getFinanceDetails(params)
      .then((res) => {
        dismissLoader();
        setFinanceDetails(res?.data?.data);
        const financeDetailsWithSrNo = res?.data?.data?.map((row, index) => ({
          ...row,
          srNo: index + 1,
        }));

        setFinanceDetails(financeDetailsWithSrNo);
      })
      .catch((err) => {
        console.log(err);
      });

    getFinanceDashBoard(params)
      .then((res) => {
        dismissLoader();
        setCardsData({ ...res?.data?.data[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dateFrom, dateTo, outletIds,online,offline]);

  function getAllRestaurantApiCall() {
    getAllRestaurant()
      .then((res) => {
        let _data = res?.data?.data;
        _data.sort((a, b) => {
          const nameA = a.outletName.toLowerCase();
          const nameB = b.outletName.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setOutlet(_data);
        setOutletIds(_data?.map((m) => m?.outletId) || []);
        dismissLoader();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    let date = minDate?.$d?.getDate() - 1;
    let year = minDate?.$y;
    let month = minDate?.$M + 1;
    setDateFrom(`${year}/${month}/${date}`);
  }, []);

  const generateExcel = (_jsonData = []) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Settlements");

    _jsonData.forEach((data) => {
      worksheet.addRow(data);
    });

    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.font = { bold: true };
      cell.alignment = {
        wrapText: true,
        vertical: "top",
        horizontal: "center",
      };
    });

    worksheet.getRow(2).eachCell({ includeEmpty: true }, (cell) => {
      cell.font = { bold: true };
      cell.alignment = {
        wrapText: true,
        vertical: "top",
        horizontal: "center",
      };
    });

    // worksheet.columns.forEach((column) => {
    //   column.width = 25;
    // });

    worksheet.properties.defaultRowHeight = 22.5;
    worksheet.properties.defaultColWidth = 25;
    worksheet.getRow(2).height = 70;

    workbook.xlsx.writeBuffer().then((excelBuffer) => {
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "Order Level Report");
    });
  };

  // Manually create an option for "AllSelected"
  const allSelectedOption = {
    outletId: -1, // Use a unique identifier for "AllSelected"
    outletName: "AllSelected",
  };

  const columns = [
    {
      field: "srNo",
      headerName: "Sr. No",
      width: 100,
      renderCell: (params) => {
        console.log("p.length , index", params.row.srNo);
        return <p>{params?.row?.srNo}</p>;
      },
      filterable: false,
    },
    {
      field: "RestaurantName",
      headerName: "Restaurant Name",
      width: 150,
      renderCell: (p) => <p>{p?.row?.outletname}</p>,
    },
    {
      field: "Campus",
      headerName: "Campus",
      width: 150,
      renderCell: (p) => <p>{p.row.campusname}</p>,
    },
    {
      field: "OrderLevelReport",
      headerName: "Order Level Report",
      width: 150,
      renderCell: (p) => (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => handleDownload(p.row.outletid)}
        >
          Download
        </Link>
      ),
    },
    {
      field: "TotalSale",
      headerName: "Total Sale",
      width: 200,
      renderCell: (p) => <p>{p.row.total_amount}</p>,
    },
    {
      field: "MealPeFees",
      headerName: "MealPe Fees",
      width: 200,
      renderCell: (p) => <p>{p.row.mealpe_fees}</p>,
    },
    {
      field: "GSTMealPeFees",
      headerName: "GST MealPe Fees",
      width: 200,
      renderCell: (p) => <p>{p.row.gst_mealpe_fees}</p>,
    },
    {
      field: "PGFees",
      headerName: "PG Fees",
      width: 200,
      renderCell: (p) => <p>{p.row.pg_fees}</p>,
    },
    {
      field: "FoodGST",
      headerName: "Food GST",
      width: 200,
      renderCell: (p) => <p>{p.row.food_gst}</p>,
    },
    {
      field: "TDS",
      headerName: "TDS",
      width: 200,
      renderCell: (p) => <p>{p.row.tds_amount}</p>,
    },
    {
      field: "TCS",
      headerName: "TCS",
      width: 200,
      renderCell: (p) => <p>{p.row.tcs_amount}</p>,
    },
    {
      field: "AmountCollected",
      headerName: "Amount Collected",
      width: 200,
      renderCell: (p) => <p>{p.row.amount_collected}</p>,
    },
    {
      field: "NeTPayout",
      headerName: "NeT Payout",
      width: 200,
      renderCell: (p) => <p>{p.row.net_amount}</p>,
    },
  ];

  const campusRestaurants = outlet.sort(
    (a, b) => -b?.campusId?.campusName.localeCompare(a?.campusId?.campusName)
  );

  return (
    <div>
      <Box gap={"4rem"}>
        <PaperBox padding={"1rem"}>
          <Box className={cssClasses.buttonContainer} alignItems={"flex-end"}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              limitTags={1}
              onChange={(event, value, reason, details) => {
                let _outletIds = [];
                if (
                  details?.option?.outletId === -1 &&
                  outlet.length === outletIds.length
                ) {
                  _outletIds = [];
                } else if (
                  details?.option?.outletId === -1 &&
                  outlet.length > outletIds.length
                ) {
                  _outletIds = outlet?.map((m) => m?.outletId);
                } else {
                  const index = outletIds?.findIndex(
                    (f) => f === details?.option?.outletId
                  );

                  if (index >= 0) {
                    // pop
                    outletIds?.splice(index, 1);
                    _outletIds = [...outletIds];
                  } else {
                    // add
                    _outletIds = [...outletIds, details?.option?.outletId];
                  }
                }
                if (reason === "clear") {
                  _outletIds = [];
                }
                setOutletIds([..._outletIds]);
              }}
              value={outletIds}
              groupBy={(option) => option?.campusId?.campusName}
              options={[allSelectedOption, ...campusRestaurants]}
              disableCloseOnSelect
              grouped
              getOptionLabel={(option) => option?.outletName}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        option?.outletId === -1
                          ? Boolean(outletIds.length === outlet?.length)
                          : Boolean(
                              outletIds.some((s) => s === option?.outletId)
                            )
                      }
                      value={option?.outletId}
                    />
                    {option?.outletName}
                  </li>
                );
              }}
              renderGroup={(params) => (
                <li {...params}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ padding: "1px 0px 0px 10px" }}
                  >
                    {params.group}
                  </Typography>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {params.children}
                  </ul>
                </li>
              )}
              style={{ width: 330 }}
              renderInput={(params) => <TextField {...params} label="Outlet" />}
              sx={{
                // width: "50rem",
                "& .MuiInputBase-root ": {
                  padding: "1px 0px 0px 10px",
                  height: "40px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                  padding: "1px 0px 0px 10px",
                },
                "& .MuiInputBase-formControl": {
                  marginTop: "2px",
                },
                "& .css-1fny1hk-MuiInputBase-root-MuiOutlinedInput-root": {
                  whiteSpace: "nowrap",
                  // overflow: 'hidden',
                  textOverflow: "ellipsis",
                  padding: "5px 0px 0px 12px",
                },
              }}
              ListboxProps={{
                "aria-labelledby": "checkboxes-list",
              }}
              renderTags={(tagValue, getTagProps) => {
                if (tagValue?.length > 2) {
                  return `${tagValue?.length} outlets selected`;
                } else {
                  return tagValue
                    .map(
                      (m) => outlet?.find((f) => f.outletId === m)?.outletName
                    )
                    .join(", ");
                }
              }}
            />

            <div style={{ display: "flex", columnGap: "8px" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                gap={"10px"}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckbox
                    name="Online"
                    label="Online"
                    value={online}
                    onChange={(e) => setOnline(e.target.checked)}
                  />
                  <CustomCheckbox
                    name="Offline"
                    label="Cash"
                    value={offline}
                    onChange={(e) => setOffline(e.target.checked)}
                  />
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="From"
                      value={dayjs(dateFrom)}
                      format="YYYY/MM/DD"
                      onChange={(e) => {
                        setDateFrom(dayjs(e));
                        let date = e?.$d.getDate() + 1;
                        let year = e?.$y;
                        let month = e?.$M + 1;
                        setMinDate(`${year}/${month}/${date}`);
                      }}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          onClick: () => setIsOpen(true),
                        },
                        //                     // field: { clearable: true, onClear: () => setDateFrom('') }
                      }}
                      open={isOpen}
                      onClose={() => setIsOpen(false)}
                      sx={{ "& .MuiInputBase-input": { height: "7px" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="To"
                      format="YYYY/MM/DD"
                      value={dayjs(dateTo)}
                      disabled={dateFrom?.toString().length > 0 ? false : true}
                      dateFormat="YYYY/MM/DD"
                      minDate={dayjs(minDate)}
                      onChange={(e) => {
                        setDateTo(dayjs(e));
                      }}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          onClick: () => setOpen(true),
                        },
                      }}
                      open={Open}
                      onClose={() => setOpen(false)}
                      sx={{ "& .MuiInputBase-input": { height: "7px" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </div>
          </Box>

          <Box
            className={cssClasses.dashboardCardContainer}
            style={{ justifyContent: "unset" }}
          >
            {cardData.map((card, index) => (
              <FinanceCards
                key={index}
                title={card.title}
                value={card.value}
                tooltip={card.description}
                cssClasses={cssClasses}
              />
            ))}
          </Box>
        </PaperBox>

        <div
          style={{
            height: " 83vh",
            minHeight: "400px",
            width: "100%",
            marginTop: "30px",
          }}
        >
          <DataGrid
            getRowId={(orderDetailsRow) =>
              orderDetailsRow?.outletid || orderDetailsRow?.id
              
            }
            disableColumnSelector
            rowHeight={72}
            sx={dataGridStyles}
            rows={financeDetails}
            columns={columns}
            disableSelectionOnClick
            disableColumnFilter
          />
        </div>
      </Box>
    </div>
  );
};

const dataGridStyles = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F8FAFC",
  },
  // "&.MuiDataGrid-columnHeadersInner":
  // {
  //     backgroundColor:"blue"

  // }
  "&.MuiDataGrid-iconButtonContainer": {
    visiblity: "hidden",
  },
  "& .MuiDataGrid-menuIcon, .MuiDataGrid-iconButtonContainer": {
    display: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    // border : 'red 1px solid',
    outline: "none",
  },
  "& .MuiDataGrid-row.Mui-selected,.MuiDataGrid-row.Mui-selected:hover,.MuiDataGrid-row:hover":
    {
      backgroundColor: "transparent",
    },
  "& .MuiDataGrid-main": {
    // paddingBottom: "10rem"
  },
  "& .MuiSvgIcon-root": {
    // display: "none"
  },

  "& .MuiDataGrid-footerContainer": {
    "& .MuiTablePagination-displayedRows ": {
      display: "none !important",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden ",
    },
    " .MuiTablePagination-selectLabel": {
      display: "none !important",
    },
    " .MuiInputBase-root": {
      display: "none !important",
    },
  },
  "&  [title='Sort']": {
    //visibility:"hidden",
    display: "none",
    cursor: "none",
  },
  "& [title='Go to previous page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to next page']": {
    border: "1px solid #EAECF0",
    borderRadius: "0.8rem",
    marginRight: "0.5rem",
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
  },
  "& .MuiDataGrid-columnHeader": {
    //   padding: "0 4rem",

    "&:focus": {
      outline: "none",
    },
  },
  "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    //   padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiButtonBase-root": {
    fontSize: "1rem",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  "& .MuiDataGrid-virtualScroller": {
    overflowX: "scroll !important",
  },
};

function intToString(value = 0) {
  let _value = value.toString().split(".")[0];

  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum =
    _value >= 1000
      ? Math.floor(("" + value.toString().split(".")[0]).length / 3)
      : 0;
  var shortValue = parseFloat(
    suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value
  );
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}

function getFormattedCurrency(number = 0) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  number = formatter.format(number);
  number = number.toLocaleString("en-IN");
  return number;
}

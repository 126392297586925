import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const CustomCheckbox = ({
  label = "Add label prop",
  value,
  onChange,
  labelSx,
  name,
  ...otherProps
}) => {
  return (
    <FormControlLabel
      sx={{
        "& .MuiTypography-root": {
          color: "#334155",
          fontWeight: "500",
          ...labelSx,
        },
      }}
      control={<Checkbox name={name} checked={value} onChange={onChange} />}
      label={label}
      {...otherProps}
    />
  );
};

export default CustomCheckbox;

import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  InputAdornment,
  Autocomplete,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { toast } from "react-toastify";
import NotifySvg from "../../../svgComponents/NotifySvg";
import RewardSvg from "../../../svgComponents/RewardSvg";
import HelpSvg from "../../../svgComponents/HelpSvg";
import { getAllRestaurant } from "../../../services/finance";

const Info = () => {
  const [tabIndex, setTabIndex] = useState(0); // To manage active tab
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(""); // For Reward tab
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedApps, setSelectedApps] = useState({
    "com.mealpe": false,
    "com.nbscafeteria": false,
  });
  const [history, setHistory] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [outletIds,setOutletIds] = useState([]);
  const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    fetchCustomers();
    fetchRestaurants();
   
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 2) {
      fetchHistory();
    }
  }, [outletIds, tabIndex, fromDate, toDate]);

  useEffect(() => {
    const filtered = customers?.filter((customer) =>
      customer?.customerName
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase())
    );
    setFilteredCustomers(filtered);
    setPage(0);
  }, [customers, searchQuery]);

  const fetchRestaurants = async () => {
    
    getAllRestaurant()
      .then((res) => {
        let _data = res?.data?.data;
        _data.sort((a, b) => {
          const nameA = a.outletName.toLowerCase();
          const nameB = b.outletName.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        console.log("restaurants ", _data);
        setRestaurants(_data);
        setOutletIds(_data?.map((m) => m?.outletId) || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchHistory = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + "wallet/userWallet/getWalletTransactionsForOutlets",
        {
          outletIds,
          fromDate,
          toDate
        }
      );
      console.log("response ", response);
      if (response?.data?.success) {
        setHistory(response?.data?.response);
      }
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_BASE_URL + "admin/getAllCustomers"
      );
      if (response?.data?.success) {
        setCustomers(response?.data?.data);
        setFilteredCustomers(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCustomers(
        filteredCustomers?.map((customer) => customer.customerAuthUID)
      );
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelectCustomer = (customerAuthUID) => {
    setSelectedCustomers((prev) => {
      if (prev.includes(customerAuthUID)) {
        return prev.filter((id) => id !== customerAuthUID);
      } else {
        return [...prev, customerAuthUID];
      }
    });
  };

  const handleAppSelection = (appId) => {
    setSelectedApps((prev) => ({
      ...prev,
      [appId]: !prev[appId],
    }));
  };

  const handleSendNotification = async () => {
    try {
      const selectedAppIds = Object.entries(selectedApps)
        .filter(([_, isSelected]) => isSelected)
        .map(([appId]) => appId);
      console.log("notify ", {
        title,
        body: description,
        selectedCustomers,
        selectedApps: selectedAppIds,
      });
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + "admin/sendNotifications",
        {
          title,
          body: description,
          selectedCustomers,
          selectedApps: selectedAppIds,
        }
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setTitle("");
        setDescription("");
        setSelectedCustomers([]);
        setSelectedApps({
          "com.mealpe": false,
          "com.nbscafeteria": false,
        });
      }
    } catch (error) {
      console.error("Error while sending notifications:", error);
    }
  };

  const handleTransaction = async () => {
    try {
      console.log("transcation ", {
        amount: Number(amount),
        customerAuthUIDs: selectedCustomers,
        description,
        isAdmin: true,
      });
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "admin/topupCustomerWalletFromAdmin",
        {
          amount: Number(amount),
          customerAuthUIDs: selectedCustomers,
          description,
          isAdmin: true,
        }
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setAmount("");
        setSelectedCustomers([]);
        setDescription("");
      }
    } catch (error) {
      console.error("Error while processing transaction:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const isNotificationButtonEnabled =
    selectedCustomers.length > 0 &&
    title.trim() !== "" &&
    description.trim() !== "" &&
    Object.values(selectedApps).some((value) => value);

  const isTransactionButtonEnabled =
    selectedCustomers.length > 0 && amount.trim() !== "";

  const renderNotifyTab = () => (
    <>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={2}
      />
      <FormGroup row sx={{ mt: 2, mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedApps["com.mealpe"]}
              onChange={() => handleAppSelection("com.mealpe")}
            />
          }
          label="Mealpe"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedApps["com.nbscafeteria"]}
              onChange={() => handleAppSelection("com.nbscafeteria")}
            />
          }
          label="NBS Cafeteria"
        />
      </FormGroup>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendNotification}
          disabled={!isNotificationButtonEnabled}
          sx={{ mt: 2, textTransform: "none" }}
        >
          Send Notification
        </Button>
      </Box>
    </>
  );

  const renderRewardTab = () => (
    <>
      <TextField
        label="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        fullWidth
        margin="normal"
        type="number"
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={2}
      />
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleTransaction}
          disabled={!isTransactionButtonEnabled}
          sx={{ mt: 2, textTransform: "none" }}
        >
          Process Transaction
        </Button>
      </Box>
    </>
  );

  const renderHistoryTab = () => (
    <>
    <Box style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="From Date"
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="To Date"
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box style={{marginBottom:'10px'}}>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        renderTags={() => null}  // Add this line to hide the chips
        limitTags={outletIds.length === restaurants.length ? 0 : 1}
        onChange={(event, value) => {
          const selectedIds = value.map(restaurant => restaurant.outletId);
          setOutletIds(selectedIds);
          fetchHistory();
        }}
        value={restaurants.filter(restaurant => outletIds.includes(restaurant.outletId))}
        options={restaurants}
        groupBy={(option) => option?.campusId?.campusName}
        getOptionLabel={(option) => option?.outletName || ''}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox 
              checked={selected}
              style={{ marginRight: 8 }}
            />
            {option?.outletName}
          </li>
        )}
        renderGroup={(params) => (
          <li>
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ padding: "1px 0px 0px 10px" }}
            >
              {params.group}
            </Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {params.children}
            </ul>
          </li>
        )}
        style={{ width: 330 }}
        renderInput={(params) => (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={outletIds.length === restaurants.length}
                  indeterminate={outletIds.length > 0 && outletIds.length < restaurants.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOutletIds(restaurants.map(r => r.outletId));
                    } else {
                      setOutletIds([]);
                    }
                    fetchHistory();
                  }}
                />
              }
              label="Select All Restaurants"
            />
            <TextField {...params} label="Outlet" />
          </Box>
        )}
        sx={{
          "& .MuiInputBase-root ": {
            padding: "1px 0px 0px 10px",
            height: "40px",
          },
          "& .MuiOutlinedInput-root": {
            height: "40px", 
            padding: "1px 0px 0px 10px",
          },
          "& .MuiInputBase-formControl": {
            marginTop: "2px",
          },

        }}
    
      />
      </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{new Date(item.created_at).toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
                <TableCell>{item.bearer}</TableCell>
                <TableCell>{item.transactionType}</TableCell>
                <TableCell style={{color: item.transactionType === 'CREDIT' ? 'green' : 'red'}}>
                  {item.transactionType === 'CREDIT' ? '+' : '-'} {item.amount}
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <Box>
      <h2 style={{ marginTop: "1rem" }}>
        {tabIndex === 0 
          ? "Send Customized Notifications" 
          : tabIndex === 1 
            ? "Process Transactions"
            : "Transaction History"
        }
      </h2>
      <Tabs
        value={tabIndex}
        onChange={(e, newIndex) => {
          setTabIndex(newIndex);
          setDescription("");
          setSelectedCustomers([]);
        }}
        aria-label="Notify and Reward Tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: "blue", // Change the underline color
          },
        }}
      >
        <Tab
          icon={<NotifySvg tabIndex={tabIndex} />}
          label="Notify"
          sx={{
            display: "flex",
            flexDirection: "row", // Icon and label in the same row
            justifyContent: "center",
            alignItems: "center",
            gap: "8px", // Space between icon and label
            textTransform: "none", // Keep text as provided
          }}
        />
        <Tab
          icon={<RewardSvg tabIndex={tabIndex} />}
          label="Reward"
          sx={{
            display: "flex",
            flexDirection: "row", // Icon and label in the same row
            justifyContent: "center",
            alignItems: "center",
            gap: "8px", // Space between icon and label
            textTransform: "none", // Keep text as provided
          }}
        />
        <Tab
          icon={<HelpSvg tabIndex={tabIndex} />}
          label="History"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center", 
            alignItems: "center",
            gap: "8px",
            textTransform: "none",
          }}
        />
      </Tabs>

      <Box mt={3}>
        {tabIndex === 0 && renderNotifyTab()}
        {tabIndex === 1 && renderRewardTab()}
        {tabIndex === 2 && renderHistoryTab()}
      </Box>
      {tabIndex !== 2 && (
        <>
          <TextField
            variant="outlined"
            placeholder="Search customers"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedCustomers.length > 0 &&
                        selectedCustomers.length < filteredCustomers.length
                      }
                      checked={
                        filteredCustomers.length > 0 &&
                        selectedCustomers.length === filteredCustomers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Mobile</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCustomers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((customer) => (
                    <TableRow key={customer.customerAuthUID}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedCustomers.includes(
                            customer.customerAuthUID
                          )}
                          onChange={() =>
                            handleSelectCustomer(customer.customerAuthUID)
                          }
                        />
                      </TableCell>
                      <TableCell>{customer.customerName}</TableCell>
                      <TableCell>{customer.mobile}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredCustomers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default Info;
